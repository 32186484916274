import React, { Component } from 'react';
import { ModuleVideo } from '../components/module-video';
import { ModuleSliderImage } from '../components/module-slider-image';
import MDReactComponent from 'markdown-react-js';
import style from '../components/module-slider.module.scss';

let TinySlider;
let TinySliderComponent;

const sliderSettings = {
  lazyload: true,
  nav: false,
  mouseDrag: true
};

/**
 * @module module-slider
 */
class ModuleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  componentDidMount() {
    TinySliderComponent = require('tiny-slider-react');
    TinySlider = TinySliderComponent.default;
    this.forceUpdate();
  }

  onIndexChanged = evt => {
    this.setState({ activeIndex: evt.index % this.props.sections.length });
  };

  render() {
    const props = this.props;
    let slider = null;

    if (TinySlider) {
      slider = (
        <TinySlider settings={sliderSettings} onIndexChanged={this.onIndexChanged}>
          {props.sections.map((props, i) => {
            switch (props.type) {
              case 'image':
                return (
                  <div key={props.image}>
                    <ModuleSliderImage {...props} />
                  </div>
                );
              case 'video-fs':
              case 'video-yt':
                return (
                  <div key={props.link}>
                    <ModuleVideo {...props} active={i === this.state.activeIndex} />
                  </div>
                );
              default:
                return null;
            }
          })}
        </TinySlider>
      );
    }
    return (
      <div className={'module-slider ' + style.sliderOuter} style={{ textAlign: 'center' }}>
        <div className={style.sliderContainer}>
          {slider}

          <MDReactComponent text={props.text} />
        </div>
      </div>
    );
  }
}

export { ModuleSlider };
